<template>
  <div>
    <!-- Header -->
    <div>
      <VuePerfectScrollbar class="scrlbar">
        <div>
          <div
            class="comment-widgets mb-2 position-relative"
            v-for="comment in comments"
            :comment="comment"
            :key="comment.commenttitle"
          >
            <!-- Comment Row -->
            <div class="d-flex flex-row comment-row mt-0 my-2">

              <div
                class="comment-text w-100 pl-3 pr-3 pt-2 pb-2"
                :class="[comment.activeClass]"
              >
              <span class="text-muted float-right">{{ comment.date }}</span>
                <h6 class="font-medium">{{ comment.username }}</h6>
                <span class="d-block">{{ comment.desc }}</span>
              </div>
            </div>
          </div>
        </div>
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: "RecentComments",
  props: {
    comment: Object,
  },
  data: () => ({
    title: "RecentComments",
    comments: [
      {
        activeClass: "",
        username: "관리사무소",
        desc:
          "작업 가능 시간을 알려주시면, 교체해 드리도록 하겠습니다.",
        date: "22.08.27 11:05",
        badgebg: "bg-primary",
        status: "Pending",
      },
      {
        activeClass: "",
        username: "101동 1001호",
        desc:
          "평일 오후 7시 이후나, 주말 작업 부탁드립니다.",
        date: "22.08.28 19:23",
        badgebg: "bg-success",
        status: "Approved",
      }
    ],
  }),
  components: {
    VuePerfectScrollbar,
  },
};
</script>