<template>
  <b-row>
    <b-col cols="3">
      <TreeView :setDong="setDong" :selected="101"></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-2">현관 센서등 교체 요청의 건</h5>
          <p>101동 1001호</p>
          <hr/>
          <p>현관 쪽 센서등이 나갔어요.<br/>최대한 빠르게 교체 부탁드립니다.</p>
        </b-card-body>
        <div class="p-3 align-items-center">
        <b>코멘트</b>
        <hr/><RecentComments></RecentComments>
        <hr/>
        <b-row>
          <b-col cols="3">
            <label for="textarea-default" class="fw-medium">처리 상태</label>
              <b-form-input
                @click="openStatusChange"
                id="textarea-default"
                value="요청 확인 (변경하려면 클릭하세요)"
                readonly
                style="cursor:pointer"
              ></b-form-input>
            <br/>
            <b-form-datepicker id="example-datepicker" v-model="value" class="mb-2" size="md" placeholder="처리 예약일"></b-form-datepicker>
          </b-col>

          <b-col cols="9">
            <label for="textarea-default" class="fw-medium">코멘트 쓰기</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="내용"
              rows="5"
            ></b-form-textarea>
            <br/>            
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            pill
            class="d-flex align-items-center px-3"
          >제출</b-button>  
        </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import RecentComments from '../components/widgets/RecentComments.vue';
import TreeView from '../components/apps/TreeView';
export default {
  name: "Request",

  data: () => ({


  }),
  components: {TreeView, RecentComments},
  methods: {
    openStatusChange(){
      this.$swal.fire({
        title: '처리 상태 변경',
        html: "<input type='radio' name='status' checked>&nbsp;요청 확인</input><br/><input type='radio' name='status'>&nbsp;처리 예약</input><br/><input type='radio' name='status'>&nbsp;처리 완료</input>",
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
    },
    openPlaceStatus(){
      this.$swal.fire({
        title: '골프연습장 시간별 사용인원',
        html: "<input type='radio' name='status' checked>&nbsp;요청 확인</input><br/><input type='radio' name='status'>&nbsp;처리 예약</input><br/><input type='radio' name='status'>&nbsp;처리 완료</input>",
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
    },
    setDong(dong, isChild){
      dong; isChild;
      this.$router.back();
    }
  }
};
</script>

<style>
.form-control[readonly] {
  background-color: #ffffff;
}
</style>